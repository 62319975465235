import style from "./Industries.module.scss";
import {Col, Container, Row} from "react-bootstrap";
import Marquee from "react-easy-marquee";

export const Industries = () => {
    return (
        <Container fluid className={style.Wrapper} id={'industries'}>
            <Container fluid={'md'} className={style.Container} style={{padding: '0px'}}>
                <Row className={'w-100'}>
                    <Col lg={'6'} xs={'12'}>
                        <h2 className={style.TitleOfContentBlock}>Industries</h2>
                    </Col>
                    <Col lg={'6'} xs={'12'}>
                        <h4 className={style.DescOfContentBlock}>Expertise across diverse industries, tailored to your
                            domain</h4>
                    </Col>
                </Row>
            </Container>
            <div className={style.MarqueeWrapper}>
                <Marquee
                    duration={30000}
                    // height="220px"
                    width="100%"
                    axis="X"
                    align="center"
                    pauseOnHover={true}
                    reverse={false}
                    className={style.Marquee}
                >
                    <div className={style.Text}>
                        <p className={style.Grey}>Real Estate <span>/</span></p>
                        <p className={style.Red}>E-commerce & Retail <span>/</span></p>
                        <p className={style.Black}>FinTech & Blockchain <span>/</span></p>
                        <p className={style.Grey}>Education <span>/</span></p>
                        <p className={style.Black}>Logistics <span>/</span></p>
                        <p className={style.Red}>E-commerce & Retail <span>/</span></p>
                    </div>
                </Marquee>
                <Marquee
                    duration={30000}
                    width="100%"
                    axis="X"
                    align="center"
                    pauseOnHover={true}
                    reverse={true}
                    className={style.Marquee}
                >
                    <div className={style.Text}>
                        <p className={style.Black}>Real Estate <span>/</span></p>
                        <p className={style.Red}>Education <span>/</span></p>
                        <p className={style.Grey}>E-commerce & Retail<span>/</span></p>
                        <p className={style.Black}>Telecom <span>/</span></p>
                        <p className={style.Red}>Media & Entertainment <span>/</span></p>
                        <p className={style.Grey}>Logistics <span>/</span></p>
                    </div>
                </Marquee>
                <Marquee
                    duration={30000}
                    width="100%"
                    axis="X"
                    align="center"
                    pauseOnHover={true}
                    reverse={false}
                    className={style.Marquee}
                >
                    <div className={style.Text}>
                        <p className={style.Red}>Education <span>/</span></p>
                        <p className={style.Grey}>Media & Entertainment <span>/</span></p>
                        <p className={style.Black}>Real Estate <span>/</span></p>
                        <p className={style.Red}>Government <span>/</span></p>
                        <p className={style.Grey}>Education <span>/</span></p>
                        <p className={style.Black}>Logistics <span>/</span></p>
                    </div>
                </Marquee>
            </div>
        </Container>
    )
}