import {Col, Container, Row} from "react-bootstrap";
import img from "../../assets/images/img3.png";
import style from './Cycles.module.scss'

export const Implementation = () => {

    return (
        <Container fluid className={style.Container}
                   style={{padding: '0px'}}>
            <img src={img} alt="" className={style.Image}/>
            <Row className={style.Wr}>
                <Col xs={'12'} className={'mb-5 mb-xs-4'}>
                    <h5 className={style.Title}>Coding:</h5>
                    <p className={style.Desc}>- Write code according to design specifications and coding standards.<br/>
                        - Conduct regular code reviews to ensure quality and consistency.
                    </p>
                </Col>
                <Col xs={'12'} className={'mb-5 mb-xs-4'}>
                    <h5 className={style.Title}>Unit Testing:</h5>
                    <p className={style.Desc}>- Test individual components to verify their correctness.<br/>
                        - Identify and fix defects at the code level.
                    </p>
                </Col>
                <Col xs={'12'} className={'mb-3 mb-xs-0'}>
                    <h5 className={style.Title}>Integration Testing:</h5>
                    <p className={style.Desc}>- Combine and test integrated components to ensure they work together
                        seamlessly.<br/>
                        - Address any issues arising from interactions between components.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}