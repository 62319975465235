import {Col, Container, Row} from "react-bootstrap";
import style from "./Greeting.module.scss";
import {appearanceAnimation} from "../../state/state";
import {motion} from "framer-motion";
import {Link} from 'react-scroll';

type GreetingType = {
    isOpenMenu: boolean
}

export const Greeting = (props: GreetingType) => {
    return (
        <Container fluid className={style.Wrapper} id={'greeting'}>
            <Container fluid={'md'} className={style.Container} style={{padding: '0px'}}>
                <motion.div variants={appearanceAnimation}
                            initial="hidden"
                            whileInView="visible"
                            custom={1}
                            viewport={{amount: 0.13, once: true}}
                >
                    <Row>
                        <Col md={'12'}>
                            <h2 className={style.TitleOfContentBlock}>
                                LVL UP YOUR BUSINESS WITH TAILOR-MADE SOFTWARE SOLUTIONS.
                            </h2>
                        </Col>
                        <Col md={'12'} xs={'12'} className={'d-flex flex-column'}>
                            <h4 className={style.DescOfContentBlock}> <br/>Kick off your project within a week, explore
                                fresh
                                features every 1–2 weeks, and acquire a top-notch product within your financial
                                plan</h4>
                            <Link className={style.ContactBtn}
                                  to="contact"
                                  delay={0}
                                  spy={true}
                                  offset={-150}
                                  duration={1800}
                            >
                                Contact us
                            </Link>
                        </Col>
                    </Row>
                </motion.div>
            </Container>
        </Container>
    )
}