import {Col, Container, Row} from "react-bootstrap";
import img from "../../assets/images/img4.png";
import style from './Cycles.module.scss'

export const Testing = () => {

    return (
        <Container fluid className={style.Container}
                   style={{padding: '0px'}}>
            <img src={img} alt="" className={style.Image}/>
            <Row className={style.Wr}>
                <Col xs={'12'} className={'mb-5 mb-xs-4'}>
                    <h5 className={style.Title}>System Testing:</h5>
                    <p className={style.Desc}>- Validate the complete system against the specified requirements.<br/>
                        - Identify and rectify any discrepancies.
                    </p>
                </Col>
                <Col xs={'12'} className={'mb-5 mb-xs-4'}>
                    <h5 className={style.Title}>User Acceptance Testing (UAT):</h5>
                    <p className={style.Desc}>- Allow end-users to test the system to ensure it meets their needs.<br/>
                        - Address feedback and make necessary adjustments.
                    </p>
                </Col>
                <Col xs={'12'} className={'mb-3 mb-xs-0'}>
                    <h5 className={style.Title}>Performance Testing:</h5>
                    <p className={style.Desc}>- Assess the system's performance under various conditions.<br/>
                        - Optimize for speed, responsiveness, and scalability.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}