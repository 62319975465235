import React from "react";
import {slide as Menu} from "react-burger-menu";
import style from "../navbar/Navbar.module.scss";
import {Container, Nav} from "react-bootstrap";
import {MenuToggle} from "../navbar/Navbar";
import {motion} from "framer-motion";
import {Link} from "react-scroll";

type MenuType = {
    isOpenMenu: boolean
    setIsOpenMenu: (tr: boolean) => void
    navbar: boolean
}
export const MenuComponent = (props: MenuType) => {
    return (
        <Menu width={100} right={true}
              burgerButtonClassName={style.BurgerBtn}
              className={`${style.Menu} ${props.navbar && style.MenuDarkMode}`} isOpen={props.isOpenMenu}
              onOpen={() => props.setIsOpenMenu(true)}
              onClose={() => props.setIsOpenMenu(false)}
        >

            <Container fluid className={`${style.WrapperMenu}`}>
                <Container fluid={'md'} className={style.Container}>
                    <div>
                        <Nav className={style.Column1}>
                            <Nav.Link href="#greeting" onClick={() => props.setIsOpenMenu(false)}>Main</Nav.Link>
                            <Nav.Link href="#services" onClick={() => props.setIsOpenMenu(false)}>Services</Nav.Link>
                            <Nav.Link href="#developmentCycle" onClick={() => props.setIsOpenMenu(false)}>Software
                                development cycle</Nav.Link>
                            <Link className={style.ContactBtn}
                                  to="solution"
                                  delay={0}
                                  spy={true}
                                  offset={-150}
                                  duration={1800}
                                  onClick={() => props.setIsOpenMenu(false)}
                            >
                                Development solutions
                            </Link>

                            <Nav.Link href="#industries"
                                      onClick={() => props.setIsOpenMenu(false)}>Industries</Nav.Link>
                            <Nav.Link href="#pricing" onClick={() => props.setIsOpenMenu(false)}>Pricing
                                models</Nav.Link>
                            <Nav.Link href="#cost" onClick={() => props.setIsOpenMenu(false)}>Cost of
                                development</Nav.Link>
                            <Nav.Link href="#whyUs" onClick={() => props.setIsOpenMenu(false)}>Why us</Nav.Link>
                        </Nav>
                        <ul className={style.Column}>
                            <li className={style.Title}>e-mail</li>
                            <li>hello@cbmobile.by</li>
                        </ul>
                        <div>
                            <ul className={style.Column}>
                                <li className={style.Title}>find us</li>
                                <li>Belarus, Minsk, Tolbukhina street 2, office 235</li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </Container>
        </Menu>
    )
}