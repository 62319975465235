import style from "./Footer.module.scss";
import {Col, Container, Row} from "react-bootstrap";
import {appearanceAnimation} from "../../state/state";
import {motion} from "framer-motion";
import {Link} from "react-scroll";
import React from "react";

export const Footer = () => {
    return (
        <Container fluid className={style.Wrapper} id={'footer'}>
            <motion.div variants={appearanceAnimation}
                        initial="hidden"
                        whileInView="visible"
                        custom={0.5}
                        viewport={{amount: 0.13}}
            >
                <Container fluid={'md'} className={style.Container} style={{padding: '0px'}}>
                    <Row>
                        <Col md={'7'} xs={'12'}>
                            <h2 className={style.TitleOfContentBlock}>cbmobile</h2>

                        </Col>
                        <Col xs={'12'} md={'5'} className={'justify-content-xs-start justify-content-md-end'}
                             style={{display: 'flex', alignItems: 'center'}}>
                            <h4 className={style.DescOfContentBlock}>Empowering Visions<br/> Unleashing Innovation</h4>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '60px'}}>
                        <Col md={4}>
                            <ul className={style.Column}>
                                <li>
                                    <Link className={style.ContactBtn}
                                          to="greeting"
                                          delay={0}
                                          spy={true}
                                          offset={0}
                                          duration={1800}
                                    >
                                        Main
                                    </Link></li>
                                <li>
                                    <Link className={style.ContactBtn}
                                          to="services"
                                          delay={0}
                                          spy={true}
                                          offset={-50}
                                          duration={1800}
                                    >
                                        Services
                                    </Link>
                                </li>
                                <li>
                                    <Link className={style.ContactBtn}
                                          to="developmentCycle"
                                          delay={0}
                                          spy={true}
                                          offset={-50}
                                          duration={1800}
                                    >
                                        Software development cycle
                                    </Link></li>
                                <li><Link className={style.ContactBtn}
                                          to="solution"
                                          delay={0}
                                          spy={true}
                                          offset={-400}
                                          duration={1800}
                                >
                                    Development solutions
                                </Link></li>
                                <li>
                                    <Link className={style.ContactBtn}
                                          to="industries"
                                          delay={0}
                                          spy={true}
                                          offset={-150}
                                          duration={1800}
                                    >
                                        Industries
                                    </Link></li>
                                <li>
                                    <Link className={style.ContactBtn}
                                          to="pricing"
                                          delay={0}
                                          spy={true}
                                          offset={0}
                                          duration={1800}
                                    >
                                        Pricing models
                                    </Link>
                                </li>
                                <li>
                                    <Link className={style.ContactBtn}
                                          to="cost"
                                          delay={0}
                                          spy={true}
                                          offset={-200}
                                          duration={1800}
                                    >
                                        Cost of development
                                    </Link></li>
                                <li><Link className={style.ContactBtn}
                                          to="whyUs"
                                          delay={0}
                                          spy={true}
                                          offset={0}
                                          duration={1800}
                                >
                                    Why us
                                </Link></li>
                            </ul>
                        </Col>
                        <Col md={4} className={'mt-xs-5 mt-0'}>
                            <ul className={style.Column}>
                                <li className={style.Title}>e-mail</li>
                                <li>HELLO@CBMOBILE.BY</li>
                            </ul>
                        </Col>
                        <Col md={4} className={'mt-xs-5 mt-0'}>
                            <ul className={style.Column}>
                                <li className={style.Title}>find us</li>
                                <li>Belarus, Minsk, Tolbukhina street 2, office 235</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </motion.div>
        </Container>)
}