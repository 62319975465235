import style from './ContactForm.module.scss';
import {Button, Col, Container, Dropdown, DropdownButton, Form, Row} from "react-bootstrap";
import {motion} from 'framer-motion';
import {useMediaQuery} from "@mui/material";
import {ChangeEvent, useState} from "react";
import emailjs from '@emailjs/browser';

type BudgetType =
    '$ 20 000'
    | 'Expected budget'
    | '$ 20 000 - $ 50 000'
    | '$ 50 000 - $ 100 000'
    | '$ 100 000 - $ 300 000'
    | 'More than $ 300 000'
export const ContactForm = () => {

    const [expectedBudget, setExpectedBudget] = useState<BudgetType>('Expected budget')
    const [name, setName] = useState('')
    const [nameRequired, setNameRequired] = useState('')
    const [email, setEmail] = useState('')
    const [emailRequired, setEmailRequired] = useState('')
    const [phone, setPhone] = useState(0)
    const [phoneRequired, setPhoneRequired] = useState('')
    const [idea, setIdea] = useState('')


    const submitForm = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (name === '') {
            setNameRequired('Please, enter your *field_name*')
            return;
        }
        if (!email) {
            setEmailRequired('Please, enter your *email_address correct*')
            return;
        }
        if (phone === 0) {
            setPhoneRequired('Please, enter your *phone')
            return
        }
        console.log(
            'name ' + name,
            'email' + email,
            'phone' + phone,
            'idea' + idea,
            'expectedBudget' + expectedBudget
        )
        emailjs.sendForm('service_7oeqq5n', 'template_g3h0m0m', e.target, 'vww-SrT5VviKRfcYQ')
            .then((res) => {
                console.log(res.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()

        setName('')
        setEmail('')
        setPhone(0)
        setIdea('')
        setExpectedBudget('Expected budget')
    }

    const onChangeNameHandler = (e: ChangeEvent<HTMLInputElement>) => {

        setName(e.currentTarget.value)
    }
    const onChangeEmailHandler = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)
    const onChangePhoneNumberHandler = (e: ChangeEvent<HTMLInputElement>) => setPhone(e.currentTarget.valueAsNumber)
    const onChangeTextAreaHandler = (e: ChangeEvent<HTMLTextAreaElement>) => setIdea(e.currentTarget.value)


    const useIsSmall = () => useMediaQuery('(max-width: 750px)');
    const isSmall = useIsSmall();


    const rightAnimation = isSmall ? {
        hidden: {
            x: 50,
            opacity:
                0,
        }
        ,
        visible: (custom: number) => ({
            x: 0,
            opacity: 1,
            transition: {
                duration: 0.3,
                delay: custom * 0.15,
                ease: "easeInOut"
            },
        })
    } : {
        hidden: {
            x: 100,
            opacity:
                0,
        }, visible: (custom: number) => ({
            x: 0,
            opacity: 1,
            transition: {
                duration: 0.4,
                delay: custom * 0.15,
                ease: "easeInOut"
            },
        })
    }

    const leftAnimation = isSmall ? {
        hidden: {
            x: -50,
            opacity: 0,
        },
        visible: (custom: number) => ({
            x: 0,
            opacity: 1,
            repeat: 'infinite',
            transition: {
                duration: 0.3,
                delay: custom * 0.15,
                ease: "easeInOut",
                type: 'just',

            },
        })
    } : {
        hidden: {
            x: -100,
            opacity: 0,
        }, visible: (custom: number) => ({
            x: 0,
            opacity: 1,
            repeat: 'infinite',
            transition: {
                duration: 0.4,
                delay: custom * 0.15,
                ease: "easeInOut",
                type: 'just',

            },
        })
    }

    return (
        <Container fluid className={style.Wrapper} id={"contact"}>
            <Container fluid={'md'} className={style.Container} style={{padding: '0px'}}>
                <Row>
                    <Col md={'7'}>
                        <motion.div variants={leftAnimation}
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{amount: 0.2, once: true}}
                                    custom={1.2}
                        >
                            <h2 className={style.TitleOfContentBlock}>Contact US</h2>
                        </motion.div>
                    </Col>


                    <Col lg={'5'} xs={'12'}>
                        <motion.div variants={rightAnimation}
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{amount: 0.2, once: true}}
                                    custom={1.2}
                        >
                            <h4 className={style.DescOfContentBlock}>
                                <span>Connect with our sales</span>
                                <br/>
                                team to discuss
                                your software development needs.

                            </h4>
                        </motion.div>
                    </Col>

                </Row>
                <Form className={style.FormWrapper} onSubmit={submitForm}>
                    <Row className={style.FormContainer}>

                        <Col xs={12} sm={12} md={6} style={{height: '100%'}}>
                            <motion.div variants={leftAnimation}
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{amount: 0.2, once: true}}
                                        custom={1.2}
                                        className={style.GroupOfForm}
                            >
                                <Form.Group className={style.FormGroup} controlId="formBasicName">
                                    <Form.Control type="text" placeholder="name *"
                                                  name={'name'}
                                                  onBlur={() => {
                                                      if (name === '') {
                                                          setNameRequired('Please, enter your *field_name*')
                                                      } else {
                                                          setNameRequired('')
                                                      }
                                                  }}
                                                  onChange={onChangeNameHandler}
                                                  className={style.FormInput}/>
                                    <span className={style.Required}>{nameRequired}</span>
                                </Form.Group>
                                <Form.Group className={style.FormGroup} controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="email *"
                                                  name={'email'}
                                                  onChange={onChangeEmailHandler}
                                                  onBlur={() => {
                                                      const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
                                                      console.log(format.test(email))

                                                      if (email === '') {
                                                          setEmailRequired('Please, enter your *email_address*')
                                                      } else if (!format.test(email)) {
                                                          setEmailRequired('Please, enter your *email_address correct*')
                                                      } else {
                                                          setEmailRequired('')
                                                      }
                                                  }}
                                                  className={style.FormInput}/>
                                    <span className={style.Required}>{emailRequired}</span>
                                </Form.Group>
                                <Form.Group className={style.FormGroup} controlId="formBasicPhoneNumber">
                                    <Form.Control type="number" placeholder="Phone Number *"
                                                  onChange={onChangePhoneNumberHandler}
                                                  name={'number'}
                                                  onBlur={() => {
                                                      if (phone === 0) {
                                                          setPhoneRequired('Please, enter your *phone*')
                                                      } else {
                                                          setPhoneRequired('')
                                                      }
                                                  }}
                                                  value={phone === 0 ? '' : phone}
                                                  className={style.FormInput}/>
                                    <span className={style.Required}>{phoneRequired}</span>
                                </Form.Group>
                                <Form.Group controlId="SelectBudjet" className={style.SelectWr}>
                                    <Form.Control type={'select'} name={'budget'} value={expectedBudget}
                                                  className={style.Budjet}/>
                                    <Dropdown className={style.FormSelect}>
                                        <DropdownButton id="dropdown-basic-button" title={expectedBudget}
                                                        className={style.DropdownButton}>
                                            <Dropdown.Item href="#/action-1" onClick={() => {
                                                setExpectedBudget('$ 20 000')
                                            }}> &lt; $ 20 000</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2" onClick={() => {
                                                setExpectedBudget('$ 20 000 - $ 50 000')
                                            }
                                            }>$ 20 000 - $ 50 000</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3" onClick={() => {
                                                setExpectedBudget('$ 50 000 - $ 100 000')
                                            }
                                            }>$ 50 000 - $ 100 000</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3" onClick={() => {
                                                setExpectedBudget('$ 100 000 - $ 300 000')
                                            }}>$ 100 000 - $ 300 000</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3" onClick={() => {
                                                setExpectedBudget('More than $ 300 000')
                                            }}>More than $ 300 000</Dropdown.Item>
                                        </DropdownButton>
                                    </Dropdown>
                                </Form.Group>
                            </motion.div>
                        </Col>

                        <Col xs={12} sm={12} md={6} style={{height: '100%'}}>
                            <motion.div variants={rightAnimation}
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{amount: 0.2, once: true}}
                                        custom={1.2}
                                        style={{height: '100%'}}
                            >
                                <Form.Group className="h-100" controlId="formBasicPhoneNumber">
                                    <Form.Control as="textarea" placeholder="Tell us about your idea or project"
                                                  value={idea}
                                                  name={'message'}
                                                  onChange={onChangeTextAreaHandler}
                                                  className={style.TextArea}/>
                                </Form.Group>
                            </motion.div>
                        </Col>
                    </Row>
                    <motion.div variants={rightAnimation}
                                initial="hidden"
                                whileInView="visible"
                                viewport={{amount: 0.2, once: true}}
                                custom={1.2}
                                className={style.BtnBlock}
                    >
                        <Form.Text className="text-muted mb-4" style={{textTransform: "uppercase"}}>
                            By sending this form, you agree to give us access to your personal data according to our
                            Privacy
                            policy.
                        </Form.Text>
                        <Button type="submit" className={style.ContactBtn}>
                            Submit
                        </Button>
                    </motion.div>
                </Form>
            </Container>
        </Container>
    )
}