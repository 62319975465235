import style from './DEvelopmentCycle.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState, MouseEvent} from "react";
import {Planing} from "./Planing";
import {Design} from "./Design";
import {Implementation} from "./Implementation";
import {Testing} from "./Testing";
import {Deployment} from "./Deployment";
import {Support} from "./Support";
import 'swiper/css';

export const DevelopmentCycle = () => {

    const [currentStep, setCurrentStep] = useState(0);

    const list = [
        {id: 1, element: <Planing/>, title: 'Planning'},
        {id: 2, element: <Design/>, title: 'Design'},
        {id: 3, element: <Implementation/>, title: 'Implementation'},
        {id: 4, element: <Testing/>, title: 'Testing'},
        {id: 5, element: <Deployment/>, title: 'Deployment'},
        {id: 6, element: <Support/>, title: 'Support'},
    ]

    const handleNav2 = (e: MouseEvent<HTMLDivElement>) => {
        e.currentTarget.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center"
        })
    }


    return (
        <Container fluid className={style.Wrapper} id={'developmentCycle'}>
            <Container fluid={'md'} className={style.Container}>
                <Row>
                    <Col md={'12'} xl={'8'}>
                        <h2 className={style.TitleOfContentBlock}>Software Development Cycle</h2>

                    </Col>
                    <Col md={'12'} xl={'4'}>
                        <h4 className={style.DescOfContentBlock}>We are certified in various development approaches such
                            as Agile, Waterfall, Scrum and Kanban</h4>
                    </Col>
                </Row>
                <div className={style.WrapperSteps}>
                    <Container
                        className={`mw-100  d-flex flex-row justify-content-between gap-5 overflow-x-auto ${style.Steps}`}
                    >
                        {
                            list.map((step, i) => {
                                    return (
                                        <div className={`${style.Step} ${currentStep === i && style.ActiveStep}`}
                                             onClick={(e) => {
                                                 console.log('i: ' + i, 'currentStep: ' + currentStep)
                                                 setCurrentStep(i);
                                                 handleNav2(e)
                                             }
                                             }
                                             key={i}>
                                            {step.title}
                                        </div>
                                    )
                                }
                            )
                        }
                    </Container>
                </div>
                <div>{list[currentStep].element}</div>
            </Container>
        </Container>
    )
}